@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');

@tailwind base;

@layer base {
  html {
    @apply text-surface;
    @apply bg-white;
  }
  html.dark {
    @apply text-neutral-50;
    @apply bg-body-dark;
  }
}

@tailwind components;
@tailwind utilities;



* {
  font-family: "Nunito", sans-serif;;
  font-optical-sizing: auto;
  scroll-behavior: smooth;
  
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v140/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Material Symbols Outlined';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialsymbolsoutlined/v107/kJF1BvYX7BgnkSrUwT8OhrdQw4oELdPIeeII9v6oDMzByHX9rA6RzaxHMPdY43zj-jCxv3fzvRNU22ZXGJpEpjC_1v-p_4MrImHCIJIZrDCvHOej.woff2) format('woff2');
}

.material-symbols-outlined {
  font-family: 'Material Symbols Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
}


p {
  @apply leading-[1.6];
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.blue {
  z-index: -10;
  transform: rotate(-20deg); 
}
react-tel-input .flag-dropdown .selected-flag {
  background-color: #E7ECEF !important;
}
.react-tel-input .flag-dropdown .selected-flag:hover {
  background-color: #E7ECEF !important; 
}

.scroll-container {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
}

.scroll-container::-webkit-scrollbar {
  display: none; 
}  

.scrollcontainer::-webkit-scrollbar {
  width: 4px; 
}

.scrollcontainer::-webkit-scrollbar-thumb {
  background-color: #ffffff; 
}

.scrollcontainer::-webkit-scrollbar-thumb:hover {
  background-color: #3A5C9C; 
}

.scroll-container::-webkit-scrollbar-track {
  background-color: #f1f1f1; 
}
.carousel {
  display: inline-flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.carousel::-webkit-scrollbar {
  display: none;
}

.carousel-item {
  box-sizing: content-box;
  display: flex;
  flex: none;
  scroll-snap-align: start;
}

.carousel-start .carousel-item {
  scroll-snap-align: start;
}

.carousel-center .carousel-item {
  scroll-snap-align: center;
}

.carousel-end .carousel-item {
  scroll-snap-align: end;
}

.react-calendar__tile--active {
  background-color: rebeccapurple;
  color: white;
}

.truncate-1-lines {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-2-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}


.darkshadow {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.seemore {
  background-color : #1552B2;
  color : white;
  padding: 2px 4px 2px 4px;
  border-radius: 5px; 
  font-size: 12px;
}




/* .calendar {
  display: flex;
  flex-direction: column;
  align-items: left;
  font-family: Arial, sans-serif;
}

.calendar-header {
  display: flex;
  align-items: left;
  justify-content: left;
  margin-bottom: 10px;
}

.calendar-nav {
  background: none;
  border: none;
  cursor: pointer;
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
  width: 100%;
  max-width: 400px;
}

.calendar-day-name,
.calendar-day {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border: 1px solid #ddd;
  height: 50px;
}

.calendar-day.empty {
  background-color: #f9f9f9;
}

.calendar-day {
  cursor: pointer;
}

.calendar-day.selected {
  background-color: #1E3354;
  color: white;
}

.calendar-day.today {
  border: 2px solid #1E3354;
} */

.image-banner {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.slides {
  display: flex;
  transition: transform 1s ease-in-out;
  width: 1300px;
}

.slides img {
  width: 1300px;
  flex-shrink: 0;
}


.toggle-switch {
  transition-property: background-color;
}

.toggle-ball {
  transition-property: transform;
}


.menu-toggler:hover+label,
.menu-toggler:hover+label::before,
.menu-toggler:hover+label::after {
  /* background: white; */
  box-shadow: 0 0 50px 5px #000;
}

.menu-toggler:checked+label {
  background: transparent;
}

.menu-toggler:checked+label::before {
  transform: rotate(45deg);
  top: 0;
  width: 2rem;
}

.menu-toggler:checked+label::after {
  transform: rotate(-45deg);
  top: 0;
  width: 2rem;
}

.menu-toggler:checked~ul .menu-item {
  opacity: 1;
}

.menu-toggler:checked~ul .menu-item:nth-child(1) {
  transform: rotate(4deg) translateX(-4.975rem);
}

.menu-toggler:checked~ul .menu-item:nth-child(2) {
  transform: rotate(45deg) translateX(-4.875rem);
}

.menu-toggler:checked~ul .menu-item:nth-child(3) {
  transform: rotate(90deg) translateX(-4.845rem);
}

.menu-toggler:checked~ul .menu-item:nth-child(4) {
  transform: rotate(132deg) translateX(-4.875rem);
}

.menu-toggler:checked~ul .menu-item:nth-child(5) {
  transform: rotate(175deg) translateX(-4.675rem);
}

.menu-item:nth-child(1) span {
  transform: rotate(0deg) translateX(0.275rem) translateY(0.175rem);
}

.menu-item:nth-child(2) span {
  transform: rotate(-45deg) translateX(0.275rem) translateY(0.175rem);
}

.menu-item:nth-child(3) span {
  transform: rotate(-90deg) translateX(0.275rem) translateY(0.175rem);
}

.menu-item:nth-child(4) span {
  transform: rotate(-130deg) translateX(0.175rem) translateY(0.235rem);
}

.menu-item:nth-child(5) span {
  transform: rotate(7deg);
}


/*common background color  */
.bg-Inside{
  background-color: #E7ECEF;
}



 


/* dropdown selct  */

.custom-select {
  color: #787878; /* Gray color for the placeholder option */
}

.custom-select option {
  color: #000; /* Default color for options */
}

.custom-select option[value=""] {
  color: #787878;
}

.custom-select:focus {
  outline: none;
}

.custom-select option:checked {
  background-color: #e7ecef;
}

.custom-select option:focus {
  background-color: #e7ecef;
}


/* ----color-theme----- */
[data-theme='light'] {
  --bg-color: #ffffff;
  --text-color: #667085;
}

[data-theme='dark'] {
  --bg-color: #242222;
  --text-color: white;
}

.placeholder-dark::placeholder {
  color: white;
}

.placeholder-light::placeholder {
  color: #787878;
}

body {
  background-color: var(--bg-color);
  color: var(--text-color);
  transition: background-color 0.3s ease, color 0.3s ease;
}



@media (min-width: 375px) and  (max-width: 424px) {
  .custom-right {
    right: 10px;
  }
}

@media (min-width: 425px) {
  .custom-right {
    right: 10px;
  }
}

@media (min-width: 375px) and  (max-width: 424px) {
  .svg-right {
    left: 15px;
  }
}

@media (min-width: 425px) {
  .svg-right {
    left: 40px;
  }
}

@media (min-width: 320px) and  (max-width: 374px) {
  .content-right {
    left: 25px;
  }
}
@media (min-width: 375px) and  (max-width: 424px) {
  .content-right {
    left: 30px;
  }
}

@media (min-width: 425px) {
  .content-right {
    left: 55px;
  }
}



.cs-message {
  background-color: #f0f0f0; /* Change message background */
  color: #333; /* Text color */
}
.cs-message-input {
  border-radius: 5px; /* Custom border style */
}



.darkshadow {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 5px;
}
